import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Image from "next/image";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  errorText: {
    margin: theme.spacing(4, "auto", 0),
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(),
  },
  content: {
    maxWidth: 720,
    padding: theme.spacing(0, 2),
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  goBtn: {
    marginTop: theme.spacing(3),
  }
}));

interface StatusProps {
  code: number;
  heading?: string;
  message?: string;
}

export const Status = ({
  code,
  heading = "Oops, something went wrong.",
  message = "This page doesn't exist. Try going back to our homepage.",
}: StatusProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {(() => {
          switch (code) {
            case 404: return <Image src="/img/404.svg" layout="responsive" width="480" height="173" />;
            case 5000: return <Image src="/img/5000.svg" layout="responsive" width="595" height="173" />;
          }
        })()}

        <Box className={classes.errorText}>
          <Typography variant="h1" className={classes.heading}>
            {heading}
          </Typography>
          <Typography>{message}</Typography>
          <Button className={classes.goBtn} color="primary" variant="contained" href="/">
            Go back home
          </Button>
        </Box>
      </main>
    </div>
  );
};

export default Status;
